import SignInWithMagicLink from '../components/SingInWithMagicLink';
import SignInWithGoogle from '../components/SignInWithGoogle';
import { Box, Typography, Divider } from '@mui/material';
import { authAtom } from '../atoms';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const IndexPage: React.FC = () => {
  const [ { user } ] = useAtom(authAtom);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [ navigate, user ]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 5 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 400, padding: 2, boxShadow: 2, borderRadius: 2 }}>
        <>
          <Typography variant='h5' sx={{ paddingBottom: 2, margin: 'auto' }}>
            Log in to Coderslang
          </Typography>

          <SignInWithGoogle />

          <Typography>
            <Divider sx={{ paddingTop: 2, paddingBottom: 2, color: 'darkgray' }}>Or, use my email instead</Divider>
          </Typography>

          <SignInWithMagicLink />
        </>
      </Box>
    </Box>
  );
};

export default IndexPage;
