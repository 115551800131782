// src/state/authAtom.js
import { atom } from 'jotai';
import { User } from 'firebase/auth';
import { DashboardData } from '../types';

// Define a union type for the authentication status
type AuthState = {
  user: User | null;
  loading: boolean;
};

const initialAuthState: AuthState = {
  user: null,
  loading: true,
};

export const authAtom = atom(initialAuthState);
export const dashboardDataAtom = atom<DashboardData | null>(null);