import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { authAtom, dashboardDataAtom } from '../atoms'; // Assuming you have a new atom called userDetailAtom for user details
import { Button, Card, Box, CardContent, Typography, CardActions, Link, Divider } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Course } from '../types';

function DashboardPage() {
  const [ { user } ] = useAtom(authAtom);
  const [ dashboardData, setDashboardData ] = useAtom(dashboardDataAtom); // Get the userDetail atom and its setter

  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/');
  };

  useEffect(() => {
    // Fetch user details from the API
    const fetchUserDetails = async () => {
      try {
        const idToken = await user?.getIdToken(true);

        if (!idToken) {
          throw new Error('User token is missing');
        }

        //TODO: move API_URL to an environment variable
        const response = await fetch('https://back23.coderslang.com/dashboard', {
          headers: {
            'x-firebase-auth': idToken,
          },
        });
        const data = await response.json();
        // Update the userDetail atom with the fetched data
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    // Only fetch user details if the user is authenticated
    if (user) {
      fetchUserDetails();
    }
  }, [ user, setDashboardData ]);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to access this component.</div>;
  }

  const courseCardMapper = (course: Course) => {
    return (
      <Card sx={{ margin: 2 }} variant='outlined'>
        <CardContent>
          <Typography color="text.primary" gutterBottom variant='h5'>
            {course.title}
          </Typography>
          <Typography variant="body2">
            {course.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button href={`https://store.coderslang.com/l/${course.slug}?email=${user.email}`} size="small">Buy now</Button>
        </CardActions>
      </Card>
    );
  }

  // Display content for authenticated users here
  return (
    <Box>
      <Typography>Welcome, <strong>{user.email}</strong>!</Typography>
      <Typography>
        Your secret access key for our <a href="https://marketplace.visualstudio.com/items?itemName=AgileNix.Coderslang23">Visual Studio Code extension</a> is:
        <Typography><strong>{`${dashboardData.user.accessKey}`}</strong></Typography>
      </Typography>
      <Typography>
        Use the access key with your email to login into the VS Code extension and start learning.
      </Typography>
      <Typography>
        Don't share the access key with anyone and make sure it doesn't appear on screenshots.
      </Typography>
      <Divider></Divider>
      <Typography><Link href="https://store.coderslang.com" target="_blank">Browse all programming courses</Link></Typography>
      <Typography sx={{ pb: 1 }}><Link href="mailto:welcome@coderslang.com" target="_blank">Send an email to welcome@coderslang.com</Link></Typography>
      {/* {dashboardData.courses.map(courseCardMapper)} */}
      {/* <Card variant="outlined">{courseCardMapper((userDetails as any).courses[1])}</Card> */}
      {/* <pre>{JSON.stringify(dashboardData, null, 2)}</pre> */}
      <Button variant='outlined' onClick={handleSignOut}>
        Sign Out
      </Button>
    </Box>
  );
}

export default DashboardPage;
