import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import IndexPage from './pages/_index';
import SignInWithMagicLinkPage from './pages/sign-in-with-magic-link';
import DashboardPage from './pages/dashboard';
import { auth } from './firebase';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useAtom } from 'jotai';
import { authAtom } from './atoms';

function App() {
  const [, setAuth] = useAtom(authAtom);

  useEffect(() => {
    // Subscribe to changes in authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // Update the Jotai atom with the new authentication state
      setAuth({ user, loading: false });
      if (user) {
        user.getIdToken(true).then(function(idToken) {
          // Send token to your backend via HTTPS
          // ...
        }).catch(function(error) {
          // Handle error
        });
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [setAuth]);

  return (
    <Router>
      <Routes>
        <Route>
          <Route path="/" element={<IndexPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/sign-in-with-magic-link" element={<SignInWithMagicLinkPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
