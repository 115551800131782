import React from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Box, Typography } from '@mui/material';
import { auth } from '../firebase';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';

const SignInWithGoogle: React.FC = () => {
  const [ signInWithGoogle, user, loading, fbError ] = useSignInWithGoogle(auth)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button sx={{ margin: 'auto', width: '100%' }} variant="outlined" onClick={() => signInWithGoogle()}>
        <GoogleIcon sx={{ marginRight: 1 }} />
        Sign in with Google
      </Button>
      {fbError && (
        <Typography textAlign="center" color="red" fontSize="10pt">
          {fbError.message}
        </Typography>
      )}
    </Box>
  )
}
export default SignInWithGoogle;