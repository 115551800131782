import { getApp, getApps, initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAme4ZGXokq1k-0ayAA_iv9VmnIF-nMfcM",
  authDomain: "coderslang23.firebaseapp.com",
  projectId: "coderslang23",
  storageBucket: "coderslang23.appspot.com",
  messagingSenderId: "223503220949",
  appId: "1:223503220949:web:e1fe6c0d2e087b4823e25c",
  measurementId: "G-7HRDPH1LRF"
};

// Initialize Firebase
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
export const auth = getAuth(app)
export const analytics = getAnalytics(app);