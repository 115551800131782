import { auth } from '../firebase'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSignInWithEmailLink } from 'react-firebase-hooks/auth'
import { Button, TextField, Box, Typography } from '@mui/material';

const SignInWithMagicLinkPage: React.FC = () => {
  const [ signInWithEmailLink, user, loading ] = useSignInWithEmailLink(auth)
  const [ email, setEmail ] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    signInAndRedirect(email);
  }

  const signInAndRedirect = async (email: string) => {
    try {
      const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

      await signInWithEmailLink(email, currentUrl)

      // Clean localStorage
      window.localStorage.removeItem('emailForSignIn')

      // Redirect user to the dashboard page
      navigate('/dashboard');
    } catch (error) {
      alert('Error in signInAndRedirect: ' + error)
    }
  }

  useEffect(() => {
    const emailFromStorage = window.localStorage.getItem('emailForSignIn')

    if (emailFromStorage) {
      signInAndRedirect(emailFromStorage)
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 400, padding: 2, boxShadow: 2, borderRadius: 2 }}>
        <Typography variant='h5'>
          Signing In With Magic Link
        </Typography>
        {loading ? (
          <Typography>
            {`⏱️ Wait while we're authenticating you...`}
          </Typography>
        ) : (
          <>
            <Typography>
              {`Please, confirm the email you used to request this sign-in link
             so we can confirm your identity.`}
            </Typography>

            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
              <TextField required={true} id="outlined-basic" label="Enter your email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />

              <Button sx={{ marginTop: 2 }} variant="outlined" type='submit'>Confirm Email and Log In</Button>
            </form>

          </>
        )}
      </Box>
    </Box>
  )
}
export default SignInWithMagicLinkPage;