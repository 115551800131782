import { auth } from '../firebase';
import { Button, Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSendSignInLinkToEmail } from 'react-firebase-hooks/auth';

const SignInWithMagicLink: React.FC = () => {
  const [ sendSignInLinkToEmail, sending, fbError ] = useSendSignInLinkToEmail(auth);
  const [ email, setEmail ] = useState('');
  const [ isSuccess, setIsSuccess ] = useState(false);

  // TODO: move magic link url to environment variables
  const actionCodeSettings = {
    url:'https://app.coderslang.com/sign-in-with-magic-link',
    handleCodeInApp: true,
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await sendSignInLinkToEmail(email, actionCodeSettings);

    // Temporarily store user's email in localStorage for match-check on sign-in page
    window.localStorage.setItem('emailForSignIn', email);

    setIsSuccess(true);
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {isSuccess ?
        (<Typography sx={{ margin: 'auto' }}>Check your email 💌</Typography>) :
        (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <TextField required={true} id="outlined-basic" label="Enter your email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />

            <Button sx={{ marginTop: 2 }} variant="outlined" type='submit'>Next</Button>
          </form>
        )
      }
    </Box>
  );
}
export default SignInWithMagicLink